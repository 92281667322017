<template lang="pug">
.card
  header.card-header
    router-link.card-header-title(:to='{ name: "ews-station", params: { id: device.id } }') {{ device.name }}
    a.card-header-icon(href="#" aria-label='Status')
      device-status(:device='device')
  .card-content
    div(v-for='variable in variablesDi')
      span.mr-2 {{ variable.name }}:
      span(v-if='variable.currentValue') {{ variable[`status${variable.currentValue.value}`] }}
    div(v-for='variable in variablesAi')
      span.mr-2 {{ variable.name }}:
      span(v-if='variable.currentValue') {{ variable.currentValue.value | percent }}
      span(v-else) -
  .card-footer
    a.card-footer-item(@click='openTelemetry') Telemetría
</template>

<script>
import DeviceStatus from '@/components/devices/DeviceStatus'
import variablesService from '@/services/variables.service'
import { variableTypeEnum } from '@/constants/enums'
import updatesService from '@/services/updates.service'
export default {
  components: { DeviceStatus },
  props: {
    device: { type: Object }
  },
  data () {
    return {
      variables: [],
      subscription: null
    }
  },
  async created () {
    this.getVaribales()
    this.subscription = updatesService.onVariablesUpdate(this.device.id).subscribe(newVariable => {
      const currentVariable = this.variables.find(variable => variable.id === newVariable.id)
      if (currentVariable) {
        Object.assign(currentVariable, newVariable)
      }
    })
  },
  beforeDestroy () {
    this.subscription.unsubscribe()
  },
  computed: {
    variablesDi () {
      return this.variables.filter(variable => variable.type === variableTypeEnum.DI)
    },
    variablesAi () {
      return this.variables.filter(variable => variable.type === variableTypeEnum.AI)
    }
  },
  methods: {
    async getVaribales (silence) {
      const typesId = [variableTypeEnum.DI, variableTypeEnum.AI].join()
      const deviceId = this.device.id
      const result = silence ? await variablesService.silence.getAll({ deviceId, typesId }) : await variablesService.getAll({ deviceId, typesId })
      if (result) {
        this.variables = result
      }
    },
    openTelemetry () {
      this.$emit('open-telemetry', this.device)
    }
  }
}
</script>
